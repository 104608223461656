var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('v-widget',{attrs:{"title":_vm.$t('menu_transfers_instructions_permanent'),"loading":_vm.loading},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-stepper',{staticClass:"mt-6 elevation-0",attrs:{"alt-labels":"","flat":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',{staticClass:"elevation-0",attrs:{"elevation":"0"}},[_vm._l((_vm.steps),function(item,i){return [_c('v-stepper-step',{key:(i + "-step"),attrs:{"complete":_vm.step > i + 1,"step":i + 1}},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t(item.title)))])]),(i + 1 < _vm.steps.length)?_c('v-divider',{key:i}):_vm._e()]})],2),_c('v-stepper-items',_vm._l((_vm.steps),function(item,i){return _c('v-stepper-content',{key:("step-content-" + i),staticClass:"mt-0 pa-0",attrs:{"step":i + 1}},[_c('ValidationObserver',{ref:"observer",refInFor:true,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(item.component,_vm._b({tag:"component",attrs:{"canPrevious":_vm.step > 1,"canNext":_vm.step < _vm.steps.length,"canSubmit":_vm.step === _vm.steps.length},on:{"next":function($event){_vm.step = _vm.step + 1},"previous":function($event){_vm.step = _vm.step - 1},"submit":_vm.handleSubmit,"cancel":_vm.handleCancel,"clear":_vm.handleClear,"changeInstructionType":_vm.handleInstructionType,"changeAccType":_vm.handleAccType,"clearIntermediary":_vm.handleClearIntermediary,"selectCountry":_vm.handleCountry,"selectPartnerBank":_vm.handlePartnerBank,"clearBank":_vm.clearBank}},'component',{
                permanentInstruction: _vm.permanentInstruction,
                partnerBanks: _vm.partnerBanks,
                countries: _vm.countries,
                invalid: invalid
              },false))]}}],null,true)})],1)}),1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }